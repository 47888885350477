// filename: browser.js


export function detectIE() {

  let ua = window.navigator.userAgent;
  let msie = ua.indexOf('MSIE ');
  let trident = ua.indexOf('Trident/');
  let edge = ua.indexOf('Edge/');

  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }
  
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}


export function isIE(version) {
	return (detectIE() === version);
}


export function redrawPseudoElements() {

	// Force IE8 to redraw :before/:after pseudo elements
	// http://stackoverflow.com/questions/9809351/

	// only run on IE8
	if (detectIE() !== 8) return;

	// creates stylesheet to hide all pseudo elements, then removes after page load
	var head = document.getElementsByTagName('head')[0],
		style = document.createElement('style');
	
	style.type = 'text/css';
	style.styleSheet.cssText = '*:before, *:after {content: none !important}';
	head.appendChild(style);

	// 'attachEvent' not 'addEventListener' for IE8
	window.attachEvent('onload', function() {
		head.removeChild(style);
	});
}


export function addClasses() {
	const html = document.querySelector('html')
	const IEVersion = detectIE()
	if (IEVersion) {
		html.classList.add('ie')
		html.classList.add('ie' + IEVersion)
	}
}

export default {
	detectIE,
	isIE,
	redrawPseudoElements,
	addClasses
}