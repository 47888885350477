
function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  gtag('event', 'conversion', {
      'send_to': 'AW-11457840375/oQdSCIa2xcEZEPeJw9cq',
      'event_callback': callback
  });
  return false;
}

function avivaTrackConversion(url ='', jobFamily = '') {
	if (!jobFamily) return;
	if (!window.location.toString().includes(url)) return;

	document.documentElement.addEventListener('click', event => {
		event.stopPropagation()
		const a = event.target.closest('a')
		if (!a || !a.href) return

		// test to see if the click was in the right sidebar, and if ther current job family is selected
		const container = event.target.closest('.m-site-header__menu--right')
		if (container) {
			const selected = container.querySelector('.js-sidebarView__jobFamily');
			if (selected && selected.value !== jobFamily) return
		}

		const isJobFamilySearchLink = a.href.includes(`jobFamily=${jobFamily}`)
		const isWorkdayLink = a.href.includes('workday')
		if (isJobFamilySearchLink || isWorkdayLink) {
			event.preventDefault();
			gtag_report_conversion(a.href);
		}
	})
}

export default function() {
	avivaTrackConversion('customer-service-claims-management', 'customer_service_claims_management');
}