
// common.js

// this file is compiled by webpack and is not within the normal require.js compilation step.
// this file is run using a <script> tag, and should only include polyfills or other globally-required scripts.

// to add imports from yarn/npm for use in other require.js scripts at runtime, use common.js

import browser from './global/browser';
import MicroModal from 'micromodal'; 
import tracking from './tracking';

window.MicroModal = MicroModal

function docReady(fn) {
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

docReady(function() {
	MicroModal.init();
	browser.addClasses();
	tracking();
});

